// store/modules/auth.js

const state = {
  filters: {
    date: [],
    sortFilter: {
      options: [
        {
          text: "Creado",
          value: "created_at",
        },
        {
          text: "ID",
          value: "unique_id",
        },
      ],
      optionValue: "created_at",
      sortModes: [
        {
          text: "Ascendente",
          value: 1,
        },
        {
          text: "Descendente",
          value: -1,
        },
      ],
      sortModeValue: -1,
    },
    searchFilter: {
      searchValue: "",
      options: [
        {
          text: "ID",
          value: "unique_id",
        },
      ],
      optionValue: "",
    },
    pagination: {
      page: 1,
      pages: 1,
      limit: 10,
    },
    expandTable: false,
  },
};

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  },
};

const actions = {
  setFilters({ commit }, filters) {
    commit("SET_FILTERS", filters);
  },
};

const getters = {
  filters: (state) => state.filters,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
