import moment from 'moment';

const initialState = {
  filters: {
    sortFilter: {
      options: [
        {
          text: 'Fecha de creación',
          value: 'created_at'
        },
        {
          text: 'ID',
          value: 'unique_id'
        }
      ],
      optionValue: 'created_at',
      sortModes: [
        {
          text: 'Ascendente',
          value: 1
        },
        {
          text: 'Descendente',
          value: -1
        }
      ],
      sortModeValue: -1
    },
    searchFilter: {
      searchValue: '',
      options: [
        {
          text: 'ID',
          value: 'unique_id'
        },
        {
          text: 'ID Panel',
          value: 'panel_id'
        },
        {
          text: 'ID Conductor',
          value: 'provider_id'
        },
        {
          text: 'Order ID',
          value: 'order_id'
        }
      ],
      optionValue: ''
    },
    statusFilter: {
      optionValue: 'all',
      options: [
        {
          text: 'Completados',
          value: 'completed'
        },
        {
          text: 'Cancelados',
          value: 'cancelled'
        },
        {
          text: 'Todos',
          value: 'all'
        }
      ]
    },
    date: [
      moment().format('YYYY-MM-DD'),
      moment().subtract(16, 'days').format('YYYY-MM-DD')
    ],
    pagination: {
      page: 1,
      pages: 1,
      limit: 10
    },
    expandTable: false
  }
};

const state = () => ({
  ...initialState
});

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  },
  RESET_FILTERS(state) {
    Object.assign(state, {
      ...initialState,
      filters: {
        ...initialState.filters,
        date: [
          moment().format('YYYY-MM-DD'),
          moment().subtract(16, 'days').format('YYYY-MM-DD')
        ]
      }
    });
  }
};

const actions = {
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  },
  resetFilters({ commit }) {
    commit('RESET_FILTERS');
  }
};

const getters = {
  apiPartnersTripHistoriesFilters: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
